import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translations
const resources = {
  en: {
    translation: {
      // Add your English translations here
      'Create Account': 'Register',
      'Family Name': 'Last Name',
      'Given Name': 'First Name',
      'Enter your Family Name': 'Enter your last name',
      'Enter your Given Name': 'Enter your first name',
      "Welcome": "Welcome",
      "Sign Out": "Logout",
      "Login": "Login",
      "Hi": "Hi",
      "About Us": "About Us",
      "Contact Us": "Contact Us",
      "Location": "Location",
      "Hours": "Hours",
      "Services": "Services",
      "Gallery": "Gallery",
      "Home": "Home",
      "My Account": "My Account",
      "Edit Site": "Edit Site",
      "View Customers": "View Customers",
      "View Layaways": "View Layaways",
      "New": "New",
      "Repair": "Repair",
      "Sale": "Sale",
      "Today's Sales": "Today's Sales",
      "My": "My",
      "Layaways": "Layaways",
      "Purchases": "Purchases",
      "Preferences": "Preferences",
      "Transactions": "Transactions",
      "Recent Transactions": "Recent Transactions",
      "Edit Public Website": "Edit Public Website",
      "MONDAY": "Monday",
      "TUESDAY": "Tuesday",
      "WEDNESDAY": "Wednesday",
      "THURSDAY": "Thursday",
      "FRIDAY": "Friday",
      "SATURDAY": "Saturday",
      "SUNDAY": "Sunday"
      // Add more translations as needed
    }
  },
  es: {
    translation: {
      'Create Account': 'Crear Cuenta',
      'Family Name': 'Apellido',
      'Given Name': 'Nombre',
      'Enter your Family Name': 'Ingrese su apellido',
      'Enter your Given Name': 'Ingrese su nombre',
      'Enter your Phone Number': 'Ingrese su número telefónico',
      'Phone Number': 'Número Telefónico',
      'Sign In': 'Iniciar Sesión',
      'Password': 'Contraseña',
      // Add your Spanish translations here
      "Welcome": "Bienvenido",
      "Sign Out": "Cerrar sesión",
      "Login": "Iniciar sesión",
      "Hi": "Hola",
      "About Us": "Sobre nosotros",
      "Contact Us": "Contáctenos",
      "Location": "Ubicación",
      "Hours": "Horario",
      "Services": "Servicios",
      "Gallery": "Galería",
      "Home": "Inicio",
      "My Account": "Mi cuenta",
      "Edit Site": "Editar sitio",
      "View Customers": "Ver clientes",
      "View Layaways": "Ver apartados",
      "New": "Nuevo",
      "Repair": "Reparación",
      "Repairs": "Reparaciónes",
      "Customers": "Clientes",
      "New Sale": "Nueva Venta",
      "Sale": "Venta",
      "Today's Sales": "Ventas de hoy",
      "My": "Mis",
      "Layaways": "Apartados",
      "Purchases": "Compras",
      "Preferences": "Preferencias",
      "Transactions": "Transacciones",
      "Recent Transactions": "Transacciones recientes",
      "Edit Public Website": "Editar sitio web público",
      "Public Website": "Pagina Web Pública",
      "Record Payment": "Tomar Pago de Apartado",
      "Repair Queue": "Lista de reparaciónes de Hoy",
      "Completed Repairs": "Reparaciónes Completos",
      "Customers": "Clientes",
      "Create Repair Job": "Crear trabajo de reparación",
      //InstallPromptTranslation:
      "Install Our App": "Instale nuestro app",
      "To use this app, you need to add it to your home screen": "Para usar esta aplicación, debes agregarla a tu pantalla de inicio",
      "Open Safari and visit our website": "Abra Safari y visite nuestro sitio web",
      "Tap the": "Toque en el",
      "icon at the bottom of the screen": "icono en la parte inferior de la pantalla",
      "Scroll down and tap": "Desplácese hacia abajo y toque",
      "Tap": "Toque",
      "Add": "Agregar",
      "on the top-right corner": "en la esquina superior derecha",

      "Add to Home Screen": "Agregar a Inicio",
      "Once added, please open the app from your home screen to continue using it": "Ya agregada, abra la aplicación desde su pantalla de inicio para continuar usándola",
      ///Static Home Page
      "Business hours are in device time":"El horario comercial se expresa en tiempo del dispositivo.",
      "Current Status": "Estado del negocio",
      "Open": "Abierto",
      "Openning Soon": "Abriendo Pronto",
      "Closed": "Cerrado",
      "Closing Soon": "Cerrando pronto",
      "MONDAY": "Lunes",
      "TUESDAY": "Martes",
      "WEDNESDAY": "Miercoles",
      "THURSDAY": "Jueves",
      "FRIDAY": "Viernes",
      "SATURDAY": "Sabado",
      "SUNDAY": "Domingo",
      //Hours Editor
      "Date": "Fecha",
      "Day": "Dia",
      "Actions": "Acciones",
      "Action": "Accion",
      "Special Hours": "Horario Especiales",
      "Add Special Hour": "Agregar Horario Especial",
      "Edit": "Modificar",
      "Delete": "Borrar",
      "Add Special Hours": "Agregar horas especiales",
      "Save": "Guardar",
      //Edit Contact and About Us:
      "Edit About Us and Contact Us": "Editar Acerca de nosotros y Contáctenos",
      "Close": "Cerrar",
      "Save Changes": "Guardar Cambios",
      "Edit Business Hours": "Editar Horario Comercial",
      "Edit Services": "Modificar Servicios",
      "Service Name": "Nombre de Servicio",
      "Service Description": "Description de Servicio",
      "Add Service": "Agregar Servicio",
      "Save": "Guardar",
      "Upload": "Subir",
      //Image Uploading
      "Next": "Siguente",
      "Prev": "Atras",
      "Loading": "Cargando",
      "Upload New Image": "Subir Nueva Imagen",
      "Description": "Descripción",
      "Tag": "Etiqueta De Imagen",
      "Add Tag": "Agregar otra etiqueta",
      "Item Number": "Número de artículo",
      //Special Hours
      "Open Time": "Hora de apertura",
      "Close Time": "Hora de cierre",
      //Customer Management Screens
      "Phone": "Teléfono",
      "Phone Number": "Número de teléfono",
      "Account Name": "Nombre de la cuenta",
      "Clear": "Borrar",
      "Enter search term": "Ingrese el término de búsqueda",
      "Customer Search": "Búsqueda de clientes",
      "New Account": "Nueva Cuenta",
      "Create Account": "Crear Cuenta",
      "New Business Account": "Nueva Cuenta de Negocio",
      "New Customer Account": "Nueva Cuenta de Cliente",
      "Business Profile": "Perfil de Negocio",
      "Business Name": "Nombre del Negocio",
      "First Name": "Nombre",
      "Last Name": "Apellido",
      "Business Phone": "Teléfono del Negocio",
      "Phone": "Teléfono",
      "Please enter a valid phone number.": "Por favor, ingrese un número de teléfono válido.",
      "Enrolled in Promotional Emails": "Inscrito en Correos Electrónicos Promocionales",
      "Business Email": "Correo Electrónico del Negocio",
      "Email": "Correo Electrónico",
      "Please enter a valid email address.": "Por favor, ingrese una dirección de correo electrónico válida.",
      "Create": "Crear",
      "Creating Account": "Creando Cuenta",
      "Cancel": "Cancelar",
      "Account Created": "Cuenta Creada",
      "Go to customer profile": "Ir al perfil del cliente",
      "Copy Customer ID": "Copiar ID del Cliente",
      "Customer ID copied to clipboard!": "¡ID del Cliente copiado al portapapeles!",
      //Customer Details Page Translation:
      "Account Name":"Nombre de Cuenta",
      "Layaway Contracts":"Contratos de Apartados",
      "Created on":" Fecha de Creacion",
      "Account Type":"Tipo de Cuenta",
      "Customer":"Cliente",
      "Business":"Negocio",
      "Email Promotions":"Promociones por Coreo Electronico",
      "Total Spend":"Gasto Total",
      "Total Savings":"Ahorros Total",
      "Reset Password":"Restablecer Contraseña",
      "Transaction Date":"Fecha de Transacción",
      "Transaction #": "Numero de Transacción",
      "Employee": "Empleado",
      "Total Items":"Numero de Articulos",
      "Balance Due": "Saldo Adeudado",
      "Repair Status": "Estado de Reparacion",
      "Completion Date":"Fecha de Terminacion",
      "Contract Start Date":"Fecha de inicio del contrato",
      "Time Remaining to Pay":"Tiempo Restante para Pagar",
      "Paid Full Date":"Fecha completa pagada",
      "Getting Account Information":"Obteniendo información de la cuenta",
      "Are you sure you want to reset the password for this customer?":"¿Está seguro de que desea restablecer la contraseña de este cliente?"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: navigator.language.split('-')[0], // Detect the language from the browser
    fallbackLng: 'en', // Use English if detected language is not available
    interpolation: {
      escapeValue: false // React already does escaping
    }
  });

export default i18n;
