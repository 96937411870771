import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment-timezone';
import Spinner from 'react-bootstrap/Spinner';
import { UnAuthGetRequest } from './Backend/APIMiddleware';
import { useTranslation } from 'react-i18next';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';

// Utility function to convert UTC time to local time
// Assumes the date and time are in Eastern Daylight Time
// Assumes the date and time are in Eastern Time (could be EST or EDT depending on date)
export const convertETToLocalTime = (date, time) => {
    if (time.toLowerCase() === "closed") {
        return "Closed";
    }

    // Ensures time is in HH:MM format (adds leading zeros if necessary)
    const formattedTime = time.match(/^(\d{2}):(\d{2})$/) ? time : `0${time}`.slice(-5);

    // Create a date-time string and parse it as Eastern Time
    const dateTimeString = `${date}T${formattedTime}`;
    const timeInEastern = moment.tz(dateTimeString, "America/New_York");

    // Convert to local time and format
    return timeInEastern.clone().tz(moment.tz.guess()).format('hh:mm A');
};


export const getCurrentStatus = (schedule) => {
    const now = moment(); // Current time as a moment object, automatically in local time

    // Assuming the server returns dates in 'YYYY-MM-DD' format
    const todayFormatted = now.format('YYYY-MM-DD');

    // Find today's schedule
    const todaySchedule = schedule.find(day => day.date === todayFormatted);
    if (todaySchedule) {
        if (todaySchedule.isClosed) {
            return 'Closed';
        }

        // Parse opening and closing times into moment objects assuming they are in 'HH:mm' format and in Eastern Time
        const [openTime, closeTime] = todaySchedule.hours[0].split(" - ");
        const open = moment.tz(`${todaySchedule.date}T${openTime}`, "America/New_York");
        const close = moment.tz(`${todaySchedule.date}T${closeTime}`, "America/New_York");

        // Compare times using moment's isBefore, isSameOrAfter, etc.
        if (now.isBefore(open)) {
            return 'Opening Soon';
        } else if (now.isSameOrAfter(close)) {
            return 'Closed';
        } else if (now.isSameOrAfter(close.subtract(1, 'hours'))) {
            return 'Closing Soon';
        } else {
            return 'Open';
        }
    }
    return 'Closed';
};

export const fetchHours = async () => {
    try {
        const response = await fetch('https://fsfpbk56je.execute-api.us-east-1.amazonaws.com/Test/v1/public/week-schedule');
        if (!response.status === 200) throw new Error('Network response was not ok');

        const data = await response.json();
        console.log(data)
        // Convert UTC hours to local time directly here, do not do it twice
        //const [open, close] = day.hours[0].split(" - ").map(time => convertUTCToLocalTime(day.date, time));

        return data
    } catch (error) {
        console.error('Failed to fetch hours:', error);
        return [];
    }
};

export function HomeScreen() {
    const { t } = useTranslation();

    const [hoursStatus, setHoursStatus] = useState([]);
    const [AboutUsDialoge,setAbout] = useState('We have been serving people in the city of Auburndale for the past 24 years and in Florida for the past 40 years.')
    const [ContactUs,setContactUs] = useState(`You can contact us at +1(863)-604-9085`)
    const [storeStatus, setStoreStatus] = useState('');
    const [servicesList, setServicesList] = useState([])
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [galleryImageList, setGalleryImages] = useState([])
    const handleShowModal = (index) => {
        setActiveImageIndex(index);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handlePrevImage = () => {
        setActiveImageIndex((prevIndex) => (prevIndex - 1 + galleryImageList.length) % galleryImageList.length);
    };

    const handleNextImage = () => {
        setActiveImageIndex((prevIndex) => (prevIndex + 1) % galleryImageList.length);
    };

    // Styles
    const galleryImageStyle = {
        cursor: 'pointer',
        objectFit: 'cover',
        width: '100%',
        maxHeight: '200px', // Adjust as needed
    };


    useEffect(() => {
        fetchHours().then(schedule => {
            setStoreStatus(getCurrentStatus(schedule));
    
            // Convert the schedule hours to local time and store in state
            const adjustedSchedule = schedule.map(day => ({
                ...day,
                hours: day.hours.map(range => range.split(" - ").map(time => convertETToLocalTime(day.date, time)).join(" - "))
            }));
    
            setHoursStatus(adjustedSchedule);
        });
    }, []);
    


    const styles = {
        navBar: {
            marginBottom: '20px',
        },
        footer: {
            marginTop: '30px',
            backgroundColor: '#f8f9fa',
            padding: '10px',
            textAlign: 'center',
            fontSize: '0.9rem',
            borderTop: '1px solid #e7e7e7',
        },
        section: {
            marginBottom: '20px',
        },
        list: {
            listStyleType: 'none', // Remove bullets
            paddingLeft: 0, // Remove default padding
        },
    };

    //Get Live data:
    useEffect(() => {
        UnAuthGetRequest(`/v1/public/about-us`).then((response) => {
            console.log(response);
            setAbout(response.data.response);
        }).catch((error)=>{
            setAbout("Falied to get About Us content")
        })
        UnAuthGetRequest(`/v1/public/contact-us`).then((response)=>{
            console.log(response)
            if(!response.data){
                console.log("No data")
            }
            setContactUs(response.data.response)

        }).catch((error)=>{
            setContactUs("No contact info available. Check back later.")
        })
        UnAuthGetRequest(`/v1/public/services`).then((response)=>{
            console.log(response)
            setServicesList(response.data)
        }).catch((error)=>{
            setServicesList([{serviceName:'Failed to get services List', serviceDescription:'Please check back in a while.'}])
        })
        UnAuthGetRequest(`/v1/public/gallery`).then((response) => {
            setGalleryImages(response.data)
        }).catch((error) => {
            console.error("Failed to get gallery images.")
        })
    }, []);


    return (
        <div className="container">
            <nav style={styles.navBar}>
            <ul className="nav nav-pills">
                    <li className="nav-item"><a className="nav-link" href="#about-us">{t("About Us")}</a></li>
                    <li className="nav-item"><a className="nav-link" href="#contact-us">{t("Contact Us")}</a></li>
                    <li className="nav-item"><a className="nav-link" href="#location">{t("Location")}</a></li>
                    <li className="nav-item"><a className="nav-link" href="#hours">{t("Hours")}</a></li>
                    <li className="nav-item"><a className="nav-link" href="#services">{t("Services")}</a></li>
                    <li className="nav-item"><a className="nav-link" href="#gallery">{t("Gallery")}</a></li>
                </ul>
            </nav>

            <section id="about-us" style={styles.section}>
                <h2>{t("About Us")}</h2>
                <p dangerouslySetInnerHTML={{__html: AboutUsDialoge}}></p>
            </section>
            <section id="contact-us" style={styles.section}>
                <h2>{t("Contact Us")}</h2>
                <p dangerouslySetInnerHTML={{__html: ContactUs}}></p>
            </section>

            <section id="location" style={styles.section}>
                <h2>{t("Location")}</h2>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3520.934444938318!2d-81.83070052452007!3d28.057026175983935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88dd6ad35fec1ced%3A0x49f354afe52b1c4!2sThe%20Jewelry%20Doctors%20of%20Auburndale%20Inc!5e0!3m2!1sen!2sus!4v1711059402452!5m2!1sen!2sus" style={{ width: "100%", height: "600px", style: "border:0;", allowfullscreen: "", loading: "lazy", referrerpolicy: "no-referrer-when-downgrade" }}></iframe>
            </section>

            <section id="hours" style={styles.section}>
                <h2>{t("Hours")}</h2>
                <p>{t("Current Status")}: {!storeStatus ? (<Spinner />) : t(storeStatus)}</p>
                <p><small>{("Business hours are in device time")}</small></p>
                {hoursStatus && (<ul style={styles.list}>
                    {hoursStatus.map((day, index) => (
                        <li key={index}>{`${t(day.weekday)}: ${t(day.hours[0])}`}</li>
                    ))}
                </ul>)}
            </section>

            <section id="services" style={styles.section}>
                <h2>{t("Services")}</h2>
                {servicesList.length === 0 && (<Spinner />)}
                <ul style={styles.list}>
                    {servicesList.map((service, index) => (
                        <li key={index}><b>{`${t(service.serviceName)}: `}</b>{` ${service.serviceDescription}`}</li>
                    ))}
                </ul>
            </section>

            <section id='gallery' className="mt-4">
                <h2>{t("Gallery")}</h2>
                <div className="row">
                    {galleryImageList.map((image, index) => (
                        <div key={index} className="col-12 col-md-6 col-lg-3 mb-4">
                            <div style={styles.imageContainer}>
                                <img
                                    src={image.imageURL}
                                    alt={image.description}
                                    style={{ cursor: 'pointer', objectFit: 'cover', width: '100%', maxHeight: '200px' }}
                                    className="img-fluid"
                                    onClick={() => handleShowModal(index)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Modal for viewing images */}
            {galleryImageList.length > 0 && (<Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
            <Modal.Header closeButton>
                        <Modal.Title>
                            {galleryImageList[activeImageIndex].description}
                            {galleryImageList[activeImageIndex].itemNumber && (
                                <> ({galleryImageList[activeImageIndex].itemNumber})</>
                            )}
                        </Modal.Title>
                    </Modal.Header>
                <Modal.Body>
                        <img
                            src={galleryImageList[activeImageIndex].imageURL}
                            alt={galleryImageList[activeImageIndex].caption}
                            className="img-fluid"
                        />
                        <Stack direction="horizontal" gap={2} className="mt-3">
                            {galleryImageList[activeImageIndex].tags.map((tag, index) => (
                                <Badge key={index} bg="dark">{tag}</Badge>
                            ))}
                        </Stack>
                    </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handlePrevImage}>&lt; {t("Prev")}</Button>
                    <Button variant="secondary" onClick={handleNextImage}>{t("Next")} &gt;</Button>
                </Modal.Footer>
            </Modal>)}


        </div>
    );
}
